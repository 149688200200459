<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        a) Consider the phase diagram for carbon dioxide shown below. What state would carbon
        dioxide be in at a temperature of
        <stemble-latex content="$-13.0\,^\circ\text{C}$" />
        and a pressure of
        <stemble-latex :content="pressure" />
      </p>

      <p class="pl-8 mb-5">
        <v-img src="/img/assignments/cpp1210s4q6.png" max-width="417px" />
      </p>

      <p class="mb-4">
        Phase:
        <v-select
          v-model="inputs.selectAnswer"
          class="d-sm-inline-block ml-3 mb-3"
          style="width: 193px"
          dense
          :items="options"
          label="Select Option"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
          <template #selection="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-2">b) I think this because:</p>

      <p class="mb-n3">
        <s-textarea v-model="inputs.writtenAnswer" outlined :counter="true" :maxlength="1200" />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemCPP1210S4_Q6',
  components: {
    ChemicalLatex,
    STextarea,
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        selectAnswer: null,
        writtenAnswer: null,
      },
      options: [
        {
          text: 'Solid',
          value: 'solid',
        },
        {
          text: 'Liquid',
          value: 'liquid',
        },
        {
          text: 'Gas',
          value: 'gas',
        },
        {
          text: 'Supercritical Fluid',
          value: 'sCFluid',
        },
      ],
    };
  },
  computed: {
    pressure() {
      let versionNum = this.taskState.getValueBySymbol('version')?.content?.value ?? 1;
      return versionNum === 1 ? '$1.0\\,\\text{atm.}$' : '$60.0\\,\\text{atm.}$';
    },
  },
};
</script>
